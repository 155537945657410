import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EntitlementsService } from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@org.quicko/ngx-core';
import { Subject } from 'rxjs';
import { HeaderComponent } from "../common/header/header.component";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatDividerModule, CommonModule, NgIf, RouterModule, HeaderComponent]
})
export class LogoutComponent implements OnInit {
  email: string | null;
destroy$ = new Subject<void>();

  constructor(private entitlementsService: EntitlementsService, private authService: AuthService,private store:Store,private actions$:Actions,private snackbarService:SnackbarService) {
    this.email = this.authService.getEmail();
  }

  ngOnInit() {
  
    this.store.dispatch(UserActions.LOGOUT());
    this.actions$.pipe(ofType(UserActions.LOGOUT_FAILURE)).subscribe((data)=>{
      this.snackbarService.openSnackBar("Failed to logout",null);
    })
    this.authService.deleteToken();

    
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

import { __decorate } from 'tslib';
import { prop, notEmpty, maxLength, pattern, minLength, compare, email } from '@rxweb/reactive-form-validators';
import * as i0 from '@angular/core';
import { InjectionToken, Pipe, Injectable, Injector, inject } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import * as i1$1 from '@angular/cdk/overlay';
import { Router, ActivatedRoute } from '@angular/router';
import * as i1$2 from '@angular/material/snack-bar';
import { TZDate } from '@date-fns/tz';
import { parse, isValid, format, startOfDay, endOfDay, differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarYears, compareAsc, add, isLeapYear } from 'date-fns';
import { plainToInstance, instanceToPlain } from 'class-transformer';
import { validateSync } from 'class-validator';
import 'reflect-metadata';
class BaseObject {
  constructor() {}
}
__decorate([prop()], BaseObject.prototype, "id", void 0);
__decorate([prop()], BaseObject.prototype, "description", void 0);
__decorate([prop()], BaseObject.prototype, "created_at", void 0);
__decorate([prop()], BaseObject.prototype, "updated_at", void 0);
class Address extends BaseObject {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.address';
  }
  static init() {
    const address = new Address();
    return address;
  }
  getfullAddress() {
    const parts = [];
    if (this.street != null) {
      parts.push(this.street);
    }
    if (this.area != null) {
      parts.push(this.area);
    }
    if (this.city != null) {
      parts.push(this.city);
    }
    if (this.country != null) {
      parts.push(this.country);
    }
    if (this.postal_code != null) {
      parts.push(this.postal_code);
    }
    return parts.join(', ');
  }
}
__decorate([prop()], Address.prototype, "@entity", void 0);
__decorate([prop()], Address.prototype, "type", void 0);
__decorate([prop()], Address.prototype, "is_primary", void 0);
__decorate([notEmpty({
  message: 'Street cannot be empty'
}), maxLength({
  value: 50,
  message: 'Maximum length exceeded'
})], Address.prototype, "street", void 0);
__decorate([notEmpty({
  message: 'Area cannot be empty'
}), maxLength({
  value: 50,
  message: 'Maximum length exceeded'
})], Address.prototype, "area", void 0);
__decorate([notEmpty({
  message: 'City cannot be empty'
}), maxLength({
  value: 50,
  message: 'Maximum length exceeded'
})], Address.prototype, "city", void 0);
__decorate([notEmpty({
  message: 'State cannot be empty'
})], Address.prototype, "state", void 0);
__decorate([prop()], Address.prototype, "district", void 0);
__decorate([prop(), notEmpty({
  message: 'Country cannot be empty'
})], Address.prototype, "country", void 0);
__decorate([notEmpty({
  message: 'PIN Code cannot be empty'
}), pattern({
  conditionalExpression: x => x.country == 'India',
  expression: {
    postal_code_pattern: /^[0-9]{6}$/
  },
  message: 'Invalid Pin Code'
}), pattern({
  conditionalExpression: x => x.country != 'India',
  expression: {
    postal_code_pattern: /^[0-9]{1,8}$/
  },
  message: 'Invalid Postal Code'
})], Address.prototype, "postal_code", void 0);

/**
 * @export
 * @class APIResponseBody
 */
class APIResponse {
  constructor(transactionId, timestamp, data, message, error) {
    this.timestamp = timestamp;
    this.transaction_id = transactionId;
    this.data = data;
    this.message = message;
    this.error = error;
  }
}
class BankAccount extends BaseObject {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.bank_account';
  }
}
__decorate([prop()], BankAccount.prototype, "@entity", void 0);
__decorate([prop(), notEmpty({
  conditionalExpression: x => !x.is_foreign_account,
  message: 'Bank Account Type is required'
})], BankAccount.prototype, "account_type", void 0);
__decorate([prop(), notEmpty({
  conditionalExpression: x => !x.is_foreign_account,
  message: 'IFSC Code is required'
}), pattern({
  expression: {
    ifsc_pattern: /^([A-Za-z]{4})([0]{1})([a-zA-Z0-9]{6})$/
  },
  message: 'Enter a valid IFSC'
})], BankAccount.prototype, "ifsc", void 0);
__decorate([prop(), notEmpty({
  message: 'Account Number is required'
}), pattern({
  conditionalExpression: x => !x.is_foreign_account,
  expression: {
    account_number_pattern: /^[a-zA-Z0-9]([/-]?(((\\d*[1-9]\\d*)*[a-zA-Z/-])|(\\d*[1-9]\\d*[a-zA-Z]*))+)*[0-9]*$/
  },
  message: 'Invalid Account Number'
}), minLength({
  conditionalExpression: x => !x.is_foreign_account,
  value: 1,
  message: 'Minimum length should be 1 digits'
}), maxLength({
  conditionalExpression: x => !x.is_foreign_account,
  value: 20,
  message: 'Maximum length exceeded'
}), minLength({
  conditionalExpression: x => x.is_foreign_account,
  value: 1,
  message: 'Account Number is required'
}), maxLength({
  conditionalExpression: x => x.is_foreign_account,
  value: 40,
  message: 'Maximum length exceeded'
})], BankAccount.prototype, "account_number", void 0);
__decorate([prop(), notEmpty({
  message: 'Name of Bank is required'
}), maxLength({
  value: 125,
  message: 'Maximum length exceeded'
})], BankAccount.prototype, "bank_name", void 0);
__decorate([prop(), notEmpty({
  conditionalExpression: x => x.is_foreign_account,
  message: 'Swift Code is required'
}), pattern({
  conditionalExpression: x => x.is_foreign_account,
  expression: {
    swift_code_pattern: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}[A-Z0-9]{0,3}$/
  },
  message: 'Invalid Swift Code'
}), minLength({
  conditionalExpression: x => x.is_foreign_account,
  value: 1,
  message: 'Swift Code is required'
}), maxLength({
  conditionalExpression: x => x.is_foreign_account,
  value: 30,
  message: 'Maximum length exceeded'
})], BankAccount.prototype, "swift_code", void 0);
__decorate([notEmpty({
  conditionalExpression: x => x.is_foreign_account,
  message: 'Country is required'
})], BankAccount.prototype, "country", void 0);
__decorate([prop()], BankAccount.prototype, "is_foreign_account", void 0);
__decorate([prop()], BankAccount.prototype, "is_refund_account", void 0);
__decorate([prop(), compare({
  fieldName: 'account_number',
  message: "Account Number doesn't match"
})], BankAccount.prototype, "confirm_account_number", void 0);
// @prop()
// @compare({
//   conditionalExpression: ((x: BankAccount) => !x.is_foreign_account),
//   fieldName: 'account_number',
//   message: "Account Number doesn't match",
// })
// confirm_account_number?: string;

class CloudEvent {}
class Contact extends BaseObject {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.contact';
  }
}
__decorate([prop()], Contact.prototype, "@entity", void 0);
__decorate([notEmpty({
  message: 'Email cannot be empty'
}), notEmpty({
  message: 'Email cannot be empty'
}), maxLength({
  value: 125,
  message: 'Max Length 125'
}), email({
  message: 'Email is incorrect'
})], Contact.prototype, "email", void 0);
__decorate([notEmpty({
  message: 'ISD code cannot be empty'
}), notEmpty(), pattern({
  expression: {
    isd_pattern: /^[0-9]{1,5}$/
  }
})], Contact.prototype, "country_code", void 0);
__decorate([notEmpty({
  message: 'Mobile Number cannot be empty'
}), pattern({
  conditionalExpression: x => x.country_code != '91',
  expression: {
    mobile_pattern: /^[1-9]{1}[0-9]{4,9}$/
  },
  message: 'Invalid Mobile Number'
}), pattern({
  conditionalExpression: x => x.country_code == '91',
  expression: {
    mobile_pattern: /^[1-9]{1}[0-9]{9}$/
  },
  message: 'Invalid Mobile Number'
})], Contact.prototype, "mobile", void 0);
class Event extends BaseObject {
  constructor(data) {
    super();
    this["@entity"] = 'org.quicko.event';
    this.data = data;
  }
}
class Faq extends BaseObject {}
class ArrayIndexOutOfBoundsException extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, ArrayIndexOutOfBoundsException.prototype);
  }
}
class IllegalArgumentException extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, IllegalArgumentException.prototype);
  }
}
class InvalidPatternException extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, InvalidPatternException.prototype);
  }
}
class NullPointerException extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, NullPointerException.prototype);
  }
}
class ObjectMapperException extends Error {
  constructor(message, code, errors) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.errors = errors;
    Object.setPrototypeOf(this, ObjectMapperException.prototype);
  }
}
class ValidationException extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, ValidationException.prototype);
  }
}
class JSONObject extends Map {
  constructor(object) {
    super();
    if (object) {
      for (const [key, value] of Object.entries(object)) {
        if (value === null || value === undefined) {
          // ignore
        } else if (value.constructor == Object) {
          this.set(key, new JSONObject(value));
        } else if (value.constructor == Array) {
          this.set(key, new JSONArray(value));
        } else {
          this.set(key, value);
        }
      }
    }
  }
  getJSONObject(key) {
    if (super.has(key)) {
      if (super.get(key) instanceof JSONObject) {
        return super.get(key);
      } else {
        throw new IllegalArgumentException("Value cannot be converted to JSONObject");
      }
    }
    throw new NullPointerException(`Value for Key: "${key}" not found`);
  }
  getString(key) {
    if (super.has(key)) {
      try {
        if (typeof super.get(key) === 'string') {
          return super.get(key);
        }
        throw new IllegalArgumentException("Value cannot be converted to string");
      } catch (e) {
        throw new IllegalArgumentException("Value cannot be converted to string");
      }
    }
    throw new NullPointerException(`Value for Key: "${key}" not found`);
  }
  getNumber(key) {
    if (super.has(key)) {
      const number = Number(super.get(key));
      if (!isNaN(number)) {
        return number;
      }
      throw new IllegalArgumentException("Value cannot be converted to number");
    }
    throw new NullPointerException(`Value for Key: "${key}" not found`);
  }
  getBoolean(key) {
    if (super.has(key)) {
      if (typeof super.get(key) === "boolean") {
        return super.get(key);
      } else if (super.get(key) === "true" || super.get(key) === "false") {
        return Boolean(super.get(key));
      }
      throw new IllegalArgumentException("Value cannot be converted to boolean");
    }
    throw new NullPointerException(`Value for Key: "${key}" not found`);
  }
  getArray(key) {
    if (super.has(key)) {
      if (super.get(key) instanceof JSONArray) {
        return super.get(key);
      } else if (super.get(key) instanceof Array) {
        return new JSONArray(super.get(key));
      }
      throw new IllegalArgumentException("Value cannot be converted to array");
    }
    throw new NullPointerException(`Value for Key: "${key}" not found`);
  }
  toJSON() {
    const obj = {};
    for (const [key, value] of this.entries()) {
      if (value == null) {
        obj[key] = value;
      } else if (value instanceof JSONObject || value instanceof JSONArray) {
        obj[key] = value.toJSON();
      } else {
        obj[key] = value;
      }
    }
    return obj;
  }
}
class JSONArray extends Array {
  constructor(array) {
    super();
    if (array) {
      for (let item of Object.values(array)) {
        item = JSON.parse(JSON.stringify(item));
        if (item == null) {
          this.push(item);
        } else if (item.constructor == Object) {
          this.push(new JSONObject(item));
        } else if (item.constructor == Array) {
          this.push(new JSONArray(item));
        } else {
          this.push(item);
        }
      }
    }
  }
  toJSON() {
    const arr = [];
    for (const value of this) {
      if (value == null) {
        arr.push(value);
      } else if (value instanceof JSONObject || value instanceof JSONArray) {
        arr.push(value.toJSON());
      } else {
        arr.push(value);
      }
    }
    return arr;
  }
}
class PaginatedList {
  constructor(items, lastEvaluatedKey) {
    this["@entity"] = 'org.quicko.paginated_list';
    this.items = [];
    this.last_evaluated_key = lastEvaluatedKey;
  }
  getItems() {
    return this.items;
  }
  setItems(value) {
    this.items = value;
  }
  getLastEvaluatedKey() {
    return this.last_evaluated_key;
  }
  setLastEvaluatedKey(value) {
    this.last_evaluated_key = value;
  }
}
__decorate([prop()], PaginatedList.prototype, "@entity", void 0);
class User extends BaseObject {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.auth.user';
  }
}
__decorate([prop()], User.prototype, "@entity", void 0);
__decorate([prop()], User.prototype, "username", void 0);
__decorate([prop()], User.prototype, "password", void 0);
__decorate([prop()], User.prototype, "role", void 0);
__decorate([prop()], User.prototype, "status", void 0);
__decorate([prop()], User.prototype, "is_locked", void 0);
__decorate([prop()], User.prototype, "last_login_epoch", void 0);
__decorate([prop()], User.prototype, "mobile", void 0);
__decorate([prop()], User.prototype, "country_code", void 0);
__decorate([prop()], User.prototype, "credential_status", void 0);
class UserDetails extends User {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.auth.user.user_details';
  }
}
__decorate([prop()], UserDetails.prototype, "@entity", void 0);
__decorate([prop()], UserDetails.prototype, "first_name", void 0);
__decorate([prop()], UserDetails.prototype, "last_name", void 0);
__decorate([prop()], UserDetails.prototype, "gender", void 0);
__decorate([prop()], UserDetails.prototype, "date_of_birth", void 0);
class UserMetadata extends BaseObject {
  constructor() {
    super(...arguments);
    this['@entity'] = 'org.quicko.auth.user_metadata';
  }
}
__decorate([prop()], UserMetadata.prototype, "@entity", void 0);
__decorate([prop()], UserMetadata.prototype, "status", void 0);
__decorate([prop()], UserMetadata.prototype, "credential_status", void 0);
const ENV_CONFIG = new InjectionToken('env.config');
class AbsoluteValuePipe {
  transform(value) {
    return +Math.abs(value);
  }
  static {
    this.ɵfac = function AbsoluteValuePipe_Factory(t) {
      return new (t || AbsoluteValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "absolute",
      type: AbsoluteValuePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbsoluteValuePipe, [{
    type: Pipe,
    args: [{
      name: 'absolute',
      standalone: true
    }]
  }], null, null);
})();
class CapitalizePipe {
  transform(value) {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  static {
    this.ɵfac = function CapitalizePipe_Factory(t) {
      return new (t || CapitalizePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "capitalize",
      type: CapitalizePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapitalizePipe, [{
    type: Pipe,
    args: [{
      name: 'capitalize',
      standalone: true
    }]
  }], null, null);
})();
class CasePipe {
  transform(value) {
    return value.toLowerCase().replace(' ', '_');
  }
  static {
    this.ɵfac = function CasePipe_Factory(t) {
      return new (t || CasePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "case",
      type: CasePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CasePipe, [{
    type: Pipe,
    args: [{
      name: 'case',
      standalone: true
    }]
  }], null, null);
})();
class DebitMoneyPipe {
  transform(amount) {
    if (isNaN(amount)) {
      return 'Invalid Amount';
    }
    const formattedAmount = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      // Set minimumFractionDigits to 0
      maximumFractionDigits: 2,
      // Set maximumFractionDigits to 2
      maximumSignificantDigits: 20
    }).format(Math.abs(amount));
    // Remove decimal places if they are .00
    const amountWithoutDecimal = formattedAmount.replace(/\.00$/, '');
    const symbolIndex = amountWithoutDecimal.indexOf('₹');
    const beforeSymbol = amountWithoutDecimal.slice(0, symbolIndex + 1);
    const afterSymbol = amountWithoutDecimal.slice(symbolIndex + 1);
    return amount > 0 ? `${beforeSymbol} (${afterSymbol})` : `${beforeSymbol} ${afterSymbol}`;
  }
  static {
    this.ɵfac = function DebitMoneyPipe_Factory(t) {
      return new (t || DebitMoneyPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "debitMoney",
      type: DebitMoneyPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DebitMoneyPipe, [{
    type: Pipe,
    args: [{
      name: 'debitMoney',
      standalone: true
    }]
  }], null, null);
})();
class EllipsisPipe {
  transform(value, limit) {
    if (!value) return '';
    if (value?.length > (limit ?? 5)) {
      const transformedString = value.substring(0, limit ?? 5) + '..';
      return transformedString;
    } else {
      return value;
    }
  }
  static {
    this.ɵfac = function EllipsisPipe_Factory(t) {
      return new (t || EllipsisPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "ellipsis",
      type: EllipsisPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EllipsisPipe, [{
    type: Pipe,
    args: [{
      name: 'ellipsis',
      standalone: true
    }]
  }], null, null);
})();

/**
 *
 *
 * @deprecated use StringPipe or NumberPipe instead
 */
class JsonValuePipe {
  transform(value) {
    if (typeof value === "string") return String(value);else if (typeof value === "number") return Number(value);else return undefined;
  }
  static {
    this.ɵfac = function JsonValuePipe_Factory(t) {
      return new (t || JsonValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "jsonValue",
      type: JsonValuePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonValuePipe, [{
    type: Pipe,
    args: [{
      name: 'jsonValue',
      standalone: true
    }]
  }], null, null);
})();
class MoneyPipe {
  transform(number) {
    // Positive or zero
    if (Math.sign(Number(number)) == 1 || Math.sign(Number(number)) == 0) {
      const value = Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumSignificantDigits: 20
      }).format(number);
      const firstDigit = value.match(/\d/);
      const symbol = value.slice(0, firstDigit != null ? firstDigit.index : undefined);
      const amount = value.slice(firstDigit != null ? firstDigit.index : undefined);
      return symbol + " " + amount;
    }
    // Negetive
    // Math.sign(Number(number)) == -1
    else {
      const value = Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumSignificantDigits: 20
      }).format(number);
      const firstDigit = value.match(/\d/);
      const symbol = value.slice(1, firstDigit != null ? firstDigit.index : undefined);
      const amount = value.slice(firstDigit != null ? firstDigit.index : undefined);
      return symbol + " -" + amount;
    }
  }
  static {
    this.ɵfac = function MoneyPipe_Factory(t) {
      return new (t || MoneyPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "money",
      type: MoneyPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MoneyPipe, [{
    type: Pipe,
    args: [{
      name: 'money',
      standalone: true
    }]
  }], null, null);
})();
class MultiplicationPipe {
  transform(number1, number2) {
    return +number1 * +number2;
  }
  static {
    this.ɵfac = function MultiplicationPipe_Factory(t) {
      return new (t || MultiplicationPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "multiply",
      type: MultiplicationPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiplicationPipe, [{
    type: Pipe,
    args: [{
      name: 'multiply',
      standalone: true
    }]
  }], null, null);
})();
class NegativeParenthesisPipe {
  transform(value) {
    return "( " + value + " )";
  }
  static {
    this.ɵfac = function NegativeParenthesisPipe_Factory(t) {
      return new (t || NegativeParenthesisPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "negativeParenthesis",
      type: NegativeParenthesisPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NegativeParenthesisPipe, [{
    type: Pipe,
    args: [{
      name: 'negativeParenthesis',
      standalone: true
    }]
  }], null, null);
})();
class NonNegetiveValuePipe {
  transform(value, considerNegetive) {
    if (!considerNegetive) {
      if (value < 0) return 0;else return value;
    } else {
      return Math.abs(value);
    }
  }
  static {
    this.ɵfac = function NonNegetiveValuePipe_Factory(t) {
      return new (t || NonNegetiveValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "nonNegetiveValue",
      type: NonNegetiveValuePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NonNegetiveValuePipe, [{
    type: Pipe,
    args: [{
      name: 'nonNegetiveValue',
      standalone: true
    }]
  }], null, null);
})();
class NumberPipe {
  transform(value) {
    return Number(value);
  }
  static {
    this.ɵfac = function NumberPipe_Factory(t) {
      return new (t || NumberPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "number",
      type: NumberPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberPipe, [{
    type: Pipe,
    args: [{
      name: 'number',
      standalone: true
    }]
  }], null, null);
})();
class PercentagePipe {
  transform(value) {
    if (isNaN(Number(value))) {
      return ''; // return empty string if the input is not a valid number
    }
    return `${value}%`; // add percentage sign as a prefix
  }
  static {
    this.ɵfac = function PercentagePipe_Factory(t) {
      return new (t || PercentagePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "percentage",
      type: PercentagePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PercentagePipe, [{
    type: Pipe,
    args: [{
      name: 'percentage',
      standalone: true
    }]
  }], null, null);
})();
class ReplacePipe {
  transform(value, searchValue, replaceValue) {
    return value.replace(searchValue, replaceValue);
  }
  static {
    this.ɵfac = function ReplacePipe_Factory(t) {
      return new (t || ReplacePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "replace",
      type: ReplacePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReplacePipe, [{
    type: Pipe,
    args: [{
      name: 'replace',
      standalone: true
    }]
  }], null, null);
})();

// Angular
/**
 * Sanitize HTML
 */
class SafePipe {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  constructor(_sanitizer) {
    this._sanitizer = _sanitizer;
  }
  /**
   * Transform
   *
   * @param value: string
   * @param type: string
   */
  transform(value, type) {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
  static {
    this.ɵfac = function SafePipe_Factory(t) {
      return new (t || SafePipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safe",
      type: SafePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafePipe, [{
    type: Pipe,
    args: [{
      name: 'safe',
      standalone: true
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], null);
})();
class SentencePipe {
  transform(value) {
    if (String(value)) {
      // snake case
      if (/[_]/.test(value)) {
        return value.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
      // camel case
      if (/[a-z][A-Z]/.test(value)) {
        return value.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
      // kebab case
      if (/-/.test(value)) {
        return value.replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
      // no case
      if (!/[A-Z]/.test(value)) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
      return value;
    } else {
      return value;
    }
  }
  static {
    this.ɵfac = function SentencePipe_Factory(t) {
      return new (t || SentencePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "sentence",
      type: SentencePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SentencePipe, [{
    type: Pipe,
    args: [{
      name: 'sentence',
      standalone: true
    }]
  }], null, null);
})();
class StringPipe {
  transform(value) {
    return String(value);
  }
  static {
    this.ɵfac = function StringPipe_Factory(t) {
      return new (t || StringPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "string",
      type: StringPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StringPipe, [{
    type: Pipe,
    args: [{
      name: 'string',
      standalone: true
    }]
  }], null, null);
})();
class StringToNumberPipe {
  transform(value) {
    return Number(value);
  }
  static {
    this.ɵfac = function StringToNumberPipe_Factory(t) {
      return new (t || StringToNumberPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "stringToNumber",
      type: StringToNumberPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StringToNumberPipe, [{
    type: Pipe,
    args: [{
      name: 'stringToNumber',
      standalone: true
    }]
  }], null, null);
})();
class JwtService extends JwtHelperService {
  getJwtTokenClaims(jwtToken) {
    return super.decodeToken(jwtToken);
  }
  getClaim(jwtToken, claim) {
    const claims = this.getJwtTokenClaims(jwtToken);
    return claims[claim];
  }
  getSubject(jwtToken) {
    const userId = this.getJwtTokenClaims(jwtToken)['sub'];
    return userId;
  }
  getAudience(jwtToken) {
    const claims = this.getJwtTokenClaims(jwtToken);
    const audience = claims['aud'];
    return audience;
  }
  getIssuer(jwtToken) {
    const claims = this.getJwtTokenClaims(jwtToken);
    const issuer = claims['iss'];
    return issuer;
  }
  getExpiry(jwtToken) {
    return super.getTokenExpirationDate(jwtToken);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵJwtService_BaseFactory;
      return function JwtService_Factory(t) {
        return (ɵJwtService_BaseFactory || (ɵJwtService_BaseFactory = i0.ɵɵgetInheritedFactory(JwtService)))(t || JwtService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JwtService,
      factory: JwtService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JwtService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class OverlayService {
  constructor(overlay) {
    this.overlay = overlay;
    this.afterClosedSubject = new Subject();
  }
  open(component, context) {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.initAfterClosedSubject();
    }
    const overlayConfig = this.overlayConfig();
    this.overlayRef = this.overlay.create(overlayConfig);
    const componentPortal = new ComponentPortal(component, null, Injector.create({
      providers: [{
        provide: DATA_INJECTION_TOKEN,
        useValue: context
      }]
    }));
    this.overlayRef.attach(componentPortal);
    this.disableBodyScroll();
  }
  close(data) {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.enableBodyScroll();
      this.afterClosedSubject.next(data);
      this.afterClosedSubject.complete();
    }
  }
  afterClosed() {
    return this.afterClosedSubject.asObservable();
  }
  overlayConfig() {
    return {
      hasBackdrop: true,
      backdropClass: 'custom-overlay-backdrop',
      panelClass: 'custom-overlay-pane',
      positionStrategy: this.overlay.position().global().centerHorizontally()
    };
  }
  disableBodyScroll() {
    document.body.classList.add('no-scroll');
  }
  enableBodyScroll() {
    document.body.classList.remove('no-scroll');
  }
  initAfterClosedSubject() {
    this.afterClosedSubject = new Subject(); // Initialize a new Subject
  }
  static {
    this.ɵfac = function OverlayService_Factory(t) {
      return new (t || OverlayService)(i0.ɵɵinject(i1$1.Overlay));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OverlayService,
      factory: OverlayService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverlayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.Overlay
  }], null);
})();
const DATA_INJECTION_TOKEN = new InjectionToken('DATA_INJECTION_TOKEN');
class QueryParamService {
  constructor() {
    this.router = inject(Router);
    this.route = inject(ActivatedRoute);
    const routeParams$ = this.route.queryParams;
    /** Read from route params subscription not from snapshot */
    routeParams$.subscribe(res => {
      this.params = Object.assign({}, res);
    });
  }
  set(key, value) {
    const urlParams = this.params;
    urlParams[key] = String(value);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: urlParams
    });
  }
  get(key) {
    const urlParams = this.route.snapshot.queryParams;
    return urlParams[key] ? urlParams[key] : null;
  }
  remove(key) {
    if (key instanceof Array) {
      const urlParams = this.params;
      key?.forEach(k => {
        delete urlParams[k];
      });
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: urlParams
      });
    } else {
      const urlParams = this.params;
      delete urlParams[key];
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: urlParams
      });
    }
  }
  clear() {
    this.router.navigate([], {
      queryParams: {}
    });
  }
  static {
    this.ɵfac = function QueryParamService_Factory(t) {
      return new (t || QueryParamService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: QueryParamService,
      factory: QueryParamService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QueryParamService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class SnackbarService {
  constructor(snackBar) {
    this.snackBar = snackBar;
  }
  openSnackBar(message, action, duration = 3000) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }
  static {
    this.ɵfac = function SnackbarService_Factory(t) {
      return new (t || SnackbarService)(i0.ɵɵinject(i1$2.MatSnackBar));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SnackbarService,
      factory: SnackbarService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SnackbarService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$2.MatSnackBar
  }], null);
})();
var AccountType;
(function (AccountType) {
  AccountType["SAVINGS"] = "savings";
  AccountType["CURRENT"] = "current";
  AccountType["OTHER"] = "other";
})(AccountType || (AccountType = {}));
var ActivationStatus;
(function (ActivationStatus) {
  ActivationStatus["ACTIVE"] = "active";
  ActivationStatus["INACTIVE"] = "inactive";
})(ActivationStatus || (ActivationStatus = {}));
var CredentialStatus;
(function (CredentialStatus) {
  CredentialStatus["ACTIVE"] = "active";
  CredentialStatus["INACTIVE"] = "inactive";
})(CredentialStatus || (CredentialStatus = {}));
var Gender;
(function (Gender) {
  /** The Male. */
  Gender["MALE"] = "male";
  /** The Female*/
  Gender["FEMALE"] = "female";
})(Gender || (Gender = {}));
var JobStatus;
(function (JobStatus) {
  JobStatus["CREATED"] = "created";
  JobStatus["QUEUED"] = "queued";
  JobStatus["CANCELLED"] = "cancelled";
  JobStatus["IN_PROGRESS"] = "in_progress";
  JobStatus["SUCCEEDED"] = "succeeded";
  JobStatus["FAILED"] = "failed";
})(JobStatus || (JobStatus = {}));

/**
 * @export
 * @enum {number}
 */
var SortOrder;
(function (SortOrder) {
  SortOrder["ASC"] = "asc";
  SortOrder["DESC"] = "desc";
})(SortOrder || (SortOrder = {}));
var ViewState;
(function (ViewState) {
  ViewState["LOADING"] = "loading";
  ViewState["SUCCESS"] = "success";
  ViewState["EMPTY"] = "empty";
  ViewState["ERROR"] = "error";
})(ViewState || (ViewState = {}));

// Reference : DateUtil.ts org.quicko.core -> integration
/**
 * Utility class for date and time operations. Provides methods for parsing,
 * formatting, and performing calculations with dates and times.
 */
class BaseDateUtil {
  static {
    this.ISO_8601_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
  }
  /**
   * Gets the current date and time in the specified time zone.
   * @param timeZone The IANA time zone identifier.
   * @returns Current date and time in the specified time zone.
   */
  static now(timeZone) {
    return new TZDate(new Date(), timeZone);
  }
  /**
   * Gets the current time in milliseconds since the Unix epoch.
   * @returns Current time in milliseconds.
   */
  static nowInMillis() {
    return Date.now();
  }
  /**
   * Parses a date string into a Date object.
   * @param dateString The date string to parse.
   * @param dateFormat The format of the date string (default: ISO_8601_FORMAT).
   * @param timeZone The IANA time zone identifier.
   * @throws {IllegalArgumentException} If the date string is invalid.
   * @returns Parsed Date object.
   */
  static readDate(dateString, dateFormat = this.ISO_8601_FORMAT, timeZone) {
    const date = parse(dateString, dateFormat, TZDate.tz(timeZone));
    if (!isValid(date)) {
      throw new IllegalArgumentException('Invalid date string or date format');
    }
    return date;
  }
  static printDate(date, timeZone, dateFormat = this.ISO_8601_FORMAT) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return format(new TZDate(normalizedDate, timeZone), dateFormat);
  }
  static getStartOfDay(date, timeZone) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return startOfDay(new TZDate(normalizedDate, timeZone));
  }
  static getEndOfDay(date, timeZone) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return endOfDay(new TZDate(normalizedDate, timeZone));
  }
  /**
   * Checks if a date string is valid according to the specified format.
   * @param dateString The date string to validate.
   * @param dateFormat Optional date format (default: ISO_8601_FORMAT).
   * @returns True if the date is valid, false otherwise.
   */
  static isValidDate(dateString, dateFormat) {
    try {
      DateUtil.readDate(dateString, dateFormat);
      return true;
    } catch (error) {
      return false;
    }
  }
  static daysInBetween(firstDate, secondDate) {
    const first = typeof firstDate === 'number' ? new Date(firstDate) : firstDate;
    const second = typeof secondDate === 'number' ? new Date(secondDate) : secondDate;
    return Math.abs(differenceInCalendarDays(first, second));
  }
  static monthsInBetween(firstDate, secondDate) {
    const first = typeof firstDate === 'number' ? new Date(firstDate) : firstDate;
    const second = typeof secondDate === 'number' ? new Date(secondDate) : secondDate;
    return Math.abs(differenceInCalendarMonths(first, second));
  }
  static yearsInBetween(firstDate, secondDate) {
    const first = typeof firstDate === 'number' ? new Date(firstDate) : firstDate;
    const second = typeof secondDate === 'number' ? new Date(secondDate) : secondDate;
    return Math.abs(differenceInCalendarYears(first, second));
  }
  static compareDates(firstDate, secondDate) {
    const first = typeof firstDate === 'number' ? new Date(firstDate) : firstDate;
    const second = typeof secondDate === 'number' ? new Date(secondDate) : secondDate;
    return compareAsc(first, second);
  }
  static addDuration(date, duration) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return add(normalizedDate, duration);
  }
  /**
   * Determines whether a given year is a leap year.
   * @param year The year to check.
   * @returns `true` if the year is a leap year, otherwise `false`.
   */
  static isLeapYear(year) {
    return isLeapYear(DateUtil.readDate(year.toString(), 'yyyy'));
  }
  /**
   * Converts a timestamp (in milliseconds) to a Date object.
   * @param milliseconds The timestamp in milliseconds since the Unix epoch.
   * @returns A Date object representing the provided timestamp.
   */
  static fromMillis(milliseconds) {
    return new Date(milliseconds);
  }
  /**
   * Converts a Date object to a timestamp (in milliseconds).
   * @param date The Date object to convert.
   * @returns The timestamp in milliseconds since the Unix epoch.
   */
  static toMillis(date) {
    return date.getTime();
  }
}
/**
 * Utility class for handling date operations in a fixed time zone.
 * Extends BaseDateUtil with default settings for the 'Asia/Kolkata' time zone.
 */
class DateUtil extends BaseDateUtil {
  /** Default time zone used for all date operations. */
  static {
    this.TIMEZONE = 'Asia/Kolkata';
  }
  /**
   * Gets the current date and time in the default time zone.
   * @returns Current date and time as a Date object.
   */
  static now() {
    return BaseDateUtil.now(this.TIMEZONE);
  }
  /**
   * Parses a date string into a Date object using the default time zone.
   * @param dateString The date string to parse.
   * @param dateFormat Optional format of the date string (default: ISO_8601_FORMAT).
   * @throws {IllegalArgumentException} If the date string is invalid.
   * @returns Parsed Date object.
   */
  static readDate(dateString, dateFormat) {
    return BaseDateUtil.readDate(dateString, dateFormat || DateUtil.ISO_8601_FORMAT, this.TIMEZONE);
  }
  static printDate(date, dateFormat = DateUtil.ISO_8601_FORMAT) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return BaseDateUtil.printDate(normalizedDate, this.TIMEZONE, dateFormat);
  }
  static getStartOfDay(date) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return BaseDateUtil.getStartOfDay(normalizedDate, this.TIMEZONE);
  }
  static getEndOfDay(date) {
    const normalizedDate = typeof date === 'number' ? new Date(date) : date;
    return BaseDateUtil.getEndOfDay(normalizedDate, this.TIMEZONE);
  }
}
class Quarter extends BaseObject {
  constructor(year, index) {
    super();
    this['@entity'] = 'org.quicko.core.beans.quarter';
    if (!FinancialYearUtil.isValidFinancialYear(year)) {
      throw new Error(`Invalid Financial Year: ${year}`);
    }
    const indexValue = typeof index === 'string' ? parseInt(index, 10) : index;
    if (isNaN(indexValue) || indexValue < 1 || indexValue > 4) {
      throw new Error(`Invalid quarter index: ${index}`);
    }
    this.year = year;
    this.index = indexValue.toString();
  }
  getIndex() {
    return this.index;
  }
  getIntegerIndex() {
    return parseInt(this.index, 10);
  }
  getYear() {
    return this.year;
  }
  getQuarter() {
    return `Q${this.index}`;
  }
  equals(quarter) {
    return quarter instanceof Quarter && this.year === quarter.year && this.index === quarter.index;
  }
  toString() {
    return `Quarter [year=${this.year}, index=${this.index}]`;
  }
}
__decorate([prop()], Quarter.prototype, "@entity", void 0);
__decorate([prop()], Quarter.prototype, "year", void 0);
__decorate([prop()], Quarter.prototype, "index", void 0);

// Reference : FinancialYearUtil.java ->  org.quicko.core integration 
/**
 * Utility class for handling financial year-related operations.
 * A financial year typically runs from April 1st of a year to March 31st of the next year.
 */
class FinancialYearUtil {
  static {
    this.FINANCIAL_YEAR_PATTERN = /^FY\s(\d{4})-(\d{2})$/;
  }
  static {
    this.DATE_PATTERN = "dd/MM/yyyy";
  }
  static getFinancialYear(date) {
    if (typeof date === 'number') {
      date = DateUtil.fromMillis(date);
    }
    let beginYear = date.getFullYear();
    if (DateUtil.compareDates(date, DateUtil.getEndOfDay(DateUtil.readDate(`31/03/${date.getFullYear()}`, FinancialYearUtil.DATE_PATTERN))) <= 0) {
      beginYear -= 1;
    }
    const endYear = beginYear + 1;
    return `FY ${beginYear}-${String(endYear % 100).padStart(2, '0')}`;
  }
  /**
   * Returns the current financial year string.
   *
   * @return current financial year string
   */
  static getCurrentFinancialYear() {
    return this.getFinancialYear(DateUtil.now());
  }
  /**
   * Returns the first day of the financial year for a given financial year string.
   *
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return first day of the financial year as Date
   * @throws InvalidPatternException if the financial year string is invalid
   */
  static getFirstDayOfFinancialYear(financialYear) {
    if (this.isValidFinancialYear(financialYear)) {
      const beginYear = this.getBeginYear(financialYear);
      return DateUtil.getStartOfDay(DateUtil.readDate(`01/04/${beginYear}`, FinancialYearUtil.DATE_PATTERN));
    } else {
      throw new InvalidPatternException(`Invalid financial year: ${financialYear}`);
    }
  }
  /**
   * Returns the last day of the financial year for a given financial year string.
   *
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return last day of the financial year as Date
   * @throws InvalidPatternException if the financial year string is invalid
   */
  static getLastDayOfFinancialYear(financialYear) {
    if (this.isValidFinancialYear(financialYear)) {
      const endYear = this.getEndYear(financialYear);
      return DateUtil.getEndOfDay(DateUtil.readDate(`31/03/${endYear}`, FinancialYearUtil.DATE_PATTERN));
    } else {
      throw new InvalidPatternException(`Invalid financial year: ${financialYear}`);
    }
  }
  /**
   * Returns the beginning year from a financial year string.
   *
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return beginning year as integer
   * @throws InvalidPatternException if the financial year string is invalid
   */
  static getBeginYear(financialYear) {
    if (this.isValidFinancialYear(financialYear)) {
      return parseInt(financialYear.substring(3, 7), 10);
    } else {
      throw new InvalidPatternException(`Invalid financial year: ${financialYear}`);
    }
  }
  /**
   * Returns the end year from a financial year string.
   *
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return end year as integer
   * @throws InvalidPatternException if the financial year string is invalid
   */
  static getEndYear(financialYear) {
    return this.getBeginYear(financialYear) + 1;
  }
  /**
   * Validates whether a given string matches the financial year pattern "FY YYYY-YY".
   *
   * @param financialYear the financial year string
   * @return true if valid, false otherwise
   */
  static isValidFinancialYear(financialYear) {
    const match = financialYear.match(this.FINANCIAL_YEAR_PATTERN);
    if (match) {
      const startYear = parseInt(match[1], 10); // Full year (e.g., 2022)
      const endYear = parseInt(match[2], 10); // Last two digits of the end year (e.g., 23)
      // Check if the end year is exactly one year ahead of the start year
      if (endYear === (startYear + 1) % 100) {
        return true;
      }
    }
    return false;
  }
  /**
  * Returns the quarter for a given date.
  *
  * @param date Date to determine the quarter for
  * @return Instance of the Quarter object representing the quarter and financial year
  * @throws Error if the date is invalid
  */
  static getQuarter(date) {
    if (date) {
      const month = date.getMonth() + 1;
      const adjustedMonth = (month + 8) % 12 + 1; // Adjust months so April is month 1
      const quarterIndex = Math.floor((adjustedMonth - 1) / 3) + 1;
      const financialYear = FinancialYearUtil.getFinancialYear(date); // Assume this method exists and returns a string
      return new Quarter(financialYear, quarterIndex);
    } else {
      throw new Error(`Invalid date: ${date}`);
    }
  }
  /**
   * Returns a list of financial years between two financial year strings.
   *
   * @param fromFy starting financial year
   * @param toFy ending financial year
   * @return list of financial year strings
   * @throws InvalidPatternException if the financial year format is invalid
   */
  static getFinancialYearsBetween(fromFy, toFy) {
    const financialYears = [];
    if (!this.isValidFinancialYear(fromFy) || !this.isValidFinancialYear(toFy) || this.compare(fromFy, toFy) > 0) {
      throw new InvalidPatternException(`Invalid financial years provided: ${fromFy}, ${toFy}`);
    }
    const beginYear = this.getBeginYear(fromFy);
    const endYear = this.getBeginYear(toFy);
    for (let year = beginYear; year <= endYear; year++) {
      financialYears.push(this.getFinancialYearFromBeginYear(year));
    }
    return financialYears;
  }
  /**
   * Returns the financial year string from a given beginning year.
   *
   * @param beginYear the beginning year of the financial year
   * @return financial year string in "FY YYYY-YY" format
   */
  static getFinancialYearFromBeginYear(beginYear) {
    const endYear = beginYear + 1;
    return `FY ${beginYear}-${String(endYear % 100).padStart(2, '0')}`;
  }
  /**
   * Compares two financial years and determines their order.
   *
   * @param fy1 first financial year string
   * @param fy2 second financial year string
   * @return 0 if equal, a negative number if fy1 is less, a positive number if fy1 is greater
   * @throws InvalidPatternException if the financial year strings are invalid
   */
  static compare(fy1, fy2) {
    return this.getBeginYear(fy1) - this.getBeginYear(fy2);
  }
  /**
   * Checks if the given date falls within the specified financial year.
   *
   * @param date the date to check
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return true if the date is in the financial year, false otherwise
   * @throws InvalidPatternException if the financial year format is invalid
   */
  static isDateInFinancialYear(date, financialYear) {
    const firstDay = this.getFirstDayOfFinancialYear(financialYear);
    const lastDay = this.getLastDayOfFinancialYear(financialYear);
    return DateUtil.compareDates(firstDay, date) <= 0 && DateUtil.compareDates(lastDay, date) >= 0;
  }
  /**
   * Returns the assessment year corresponding to the given financial year.
   *
   * @param financialYear financial year string in "FY YYYY-YY" format
   * @return assessment year string in "AY YYYY-YY" format
   * @throws InvalidPatternException if the financial year format is invalid
   */
  static getAssessmentYear(financialYear) {
    if (this.isValidFinancialYear(financialYear)) {
      const beginYear = this.getBeginYear(financialYear) + 1;
      const endYear = beginYear + 1;
      return `AY ${beginYear}-${String(endYear % 100).padStart(2, '0')}`;
    } else {
      throw new InvalidPatternException(`Invalid financial year: ${financialYear}`);
    }
  }
}
class NumberUtil {
  static getValue(value) {
    if (value === null || value === undefined) {
      return 0;
    }
    if (isNaN(Number(value))) {
      throw new IllegalArgumentException('Not a number');
    }
    return Number(value);
  }
}

/**
 * @method readValue - converts a json object to a class instance and runs validations on it
 *
 * @method with - takes in [ObjectMapperConfigurationOptions: JSONValue] to modify default configuration
 *
 * @constructor initialized ObjectMapper with the default configuration
 */
class ObjectMapper {
  constructor() {
    this.configuration = {
      'discriminator': '@entity',
      'include_null': true,
      'mixins': new Map(),
      'class_transform_options': {
        excludeExtraneousValues: true,
        exposeUnsetFields: false
      }
    };
  }
  readValue(jsonObject, classToBeInstantiated) {
    const discriminator = this.configuration['discriminator'];
    let object;
    if (this.configuration['mixins'].has(classToBeInstantiated)) {
      classToBeInstantiated = this.configuration['mixins'].get(classToBeInstantiated) ?? classToBeInstantiated;
    }
    if (Array.isArray(jsonObject)) {
      object = [];
      for (const item of jsonObject) {
        object.push(this.readValue(item, classToBeInstantiated));
      }
    } else {
      if (Reflect.hasOwnMetadata(discriminator, classToBeInstantiated) && jsonObject[discriminator] === Reflect.getOwnMetadata(discriminator, classToBeInstantiated)) {
        if (this.configuration['include_null'] === false) {
          this.removeNullValues(jsonObject);
        }
        object = plainToInstance(classToBeInstantiated, jsonObject, this.configuration.class_transform_options);
      } else if (Reflect.hasOwnMetadata('subtypes', classToBeInstantiated)) {
        const subTypes = Reflect.getOwnMetadata('subtypes', classToBeInstantiated);
        for (const subType of subTypes) {
          try {
            return this.readValue(jsonObject, subType());
          } catch (error) {
            // ignore
          }
        }
      }
    }
    if (object === undefined) {
      throw new ObjectMapperException('Failed to read value of type ' + jsonObject[discriminator] + ' into ' + Reflect.getOwnMetadata(discriminator, classToBeInstantiated));
    }
    if (Array.isArray(object)) {
      for (let i = 0; i < object.length; i++) {
        const errors = validateSync(object[i], {
          skipMissingProperties: true
        });
        if (errors.length > 0) {
          throw new IllegalArgumentException('Failed to validate: ' + JSON.stringify(errors));
        }
      }
      return object;
    } else {
      const errors = validateSync(object, {
        skipMissingProperties: true
      });
      if (errors.length > 0) {
        throw new IllegalArgumentException('Failed to validate: ' + JSON.stringify(errors));
      } else {
        return object;
      }
    }
  }
  writeValue(jsonObject) {
    try {
      if (jsonObject instanceof BaseObject) {
        const errors = validateSync(jsonObject, {
          skipMissingProperties: true
        });
        if (errors.length > 0) {
          throw new IllegalArgumentException('Failed to validate: ' + JSON.stringify(errors));
        } else {
          return instanceToPlain(jsonObject, this.configuration['class_transform_options']);
        }
      } else {
        return jsonObject;
      }
    } catch (error) {
      throw new ObjectMapperException('Failed to write value');
    }
  }
  writeValueAsString(jsonObject) {
    return JSON.stringify(this.writeValue(jsonObject));
  }
  with(config) {
    this.configuration = {
      ...this.configuration,
      ...config
    };
    return this;
  }
  removeNullValues(object) {
    for (const property in object) {
      if (object[property] === null) {
        delete object[property];
      } else if (typeof object[property] === 'object') {
        this.removeNullValues(object[property]);
      }
    }
  }
  setMixins(target, mixinSource) {
    this.configuration['mixins'].set(target, mixinSource);
  }
}
class StringBuilder {
  constructor() {
    this.parts = [];
  }
  append(part) {
    this.parts.push(part);
  }
  toString() {
    return this.parts.join('');
  }
}

/*
 * Public API Surface of ngx-org-quicko-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { APIResponse, AbsoluteValuePipe, AccountType, ActivationStatus, Address, ArrayIndexOutOfBoundsException, BankAccount, BaseObject, CapitalizePipe, CasePipe, CloudEvent, Contact, CredentialStatus, DATA_INJECTION_TOKEN, DateUtil, DebitMoneyPipe, ENV_CONFIG, EllipsisPipe, Event, Faq, FinancialYearUtil, Gender, IllegalArgumentException, InvalidPatternException, JSONArray, JSONObject, JobStatus, JsonValuePipe, JwtService, MoneyPipe, MultiplicationPipe, NegativeParenthesisPipe, NonNegetiveValuePipe, NullPointerException, NumberPipe, NumberUtil, ObjectMapper, ObjectMapperException, OverlayService, PaginatedList, PercentagePipe, QueryParamService, ReplacePipe, SafePipe, SentencePipe, SnackbarService, SortOrder, StringBuilder, StringPipe, StringToNumberPipe, User, UserDetails, UserMetadata, ValidationException, ViewState };

import * as i0 from '@angular/core';
import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { ENV_CONFIG } from '@org.quicko/ngx-core';
import { DOCUMENT } from '@angular/common';
import { CookieService as CookieService$1 } from 'ngx-cookie-service';
import * as i1 from '@angular/common/http';
class CookieService extends CookieService$1 {
  environment;
  constructor(environment, document, platformId) {
    super(document, platformId);
    this.environment = environment;
  }
  setCookie(key, value, expires, secure, sameSite, partitioned) {
    const domain = this.environment.production ? '.quicko.com' : 'localhost';
    this.set(key, value, expires, '/', domain, secure ?? true, sameSite ?? 'Lax', partitioned);
  }
  getCookie(key) {
    return this.get(key);
  }
  deleteCookie(key, secure, sameSite) {
    const domain = this.environment.production ? '.quicko.com' : 'localhost';
    this.delete(key, '/', domain, secure ?? true, sameSite ?? 'Lax');
  }
  static ɵfac = function CookieService_Factory(t) {
    return new (t || CookieService)(i0.ɵɵinject(ENV_CONFIG), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(PLATFORM_ID));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CookieService,
    factory: CookieService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ENV_CONFIG]
    }]
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
class AuthService {
  environment;
  cookieService;
  constructor(environment, cookieService) {
    this.environment = environment;
    this.cookieService = cookieService;
    this.authenticated = new BehaviorSubject(this.isAuthenticated());
  }
  authenticated;
  jwtHelper = new JwtHelperService();
  getAuthenticated() {
    return this.authenticated.asObservable();
  }
  setAuthenticated(isAuthenticated) {
    this.authenticated.next(isAuthenticated);
  }
  setToken(token) {
    this.cookieService.setCookie('SESSIONID', token, 1);
  }
  deleteToken() {
    if (this.environment.production) {
      this.cookieService.deleteCookie('SESSIONID', true, 'Strict');
    } else {
      this.cookieService.deleteCookie('SESSIONID', true, 'Strict');
    }
  }
  getToken() {
    return this.cookieService.getCookie('SESSIONID');
  }
  getUsername() {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['sub'];
    }
    return null;
  }
  getAudience() {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['aud'];
    }
    return null;
  }
  getEmail() {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['email'];
    }
    return null;
  }
  isAuthenticated() {
    try {
      if (this.getToken() != null) {
        let token = this.getToken().split('.');
        if (token && token.length == 3 && this.jwtHelper.decodeToken(this.getToken()) && !this.jwtHelper.isTokenExpired(this.getToken())) {
          return true;
        }
      }
    } catch (error) {
      return false;
    }
    return false;
  }
  getTokenExpiry(token) {
    return this.jwtHelper.getTokenExpirationDate(token);
  }
  isTokenExpired(token) {
    return this.jwtHelper.isTokenExpired(token);
  }
  static ɵfac = function AuthService_Factory(t) {
    return new (t || AuthService)(i0.ɵɵinject(ENV_CONFIG), i0.ɵɵinject(CookieService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthService,
    factory: AuthService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ENV_CONFIG]
    }]
  }, {
    type: CookieService
  }], null);
})();
class EntitlementsService {
  environment;
  httpClient;
  constructor(environment, httpClient) {
    this.environment = environment;
    this.httpClient = httpClient;
  }
  getUserDetail() {
    const url = this.environment.auth_services_endpoint + '/auth/user/detail';
    return this.httpClient.get(url);
  }
  static ɵfac = function EntitlementsService_Factory(t) {
    return new (t || EntitlementsService)(i0.ɵɵinject(ENV_CONFIG), i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: EntitlementsService,
    factory: EntitlementsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EntitlementsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ENV_CONFIG]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
class ThemeService {
  cookieService;
  theme = new BehaviorSubject(Theme.SYSTEM);
  theme$ = this.theme.asObservable();
  systemThemePreference = Theme.SYSTEM;
  userThemePreference;
  clientThemePreference;
  renderer;
  constructor(rendererFactory, cookieService) {
    this.cookieService = cookieService;
    this.renderer = rendererFactory.createRenderer(null, null);
    const base64Theme = new URLSearchParams(window.location.search).get('theme');
    if (base64Theme) {
      const jsonString = atob(base64Theme);
      const themeJSON = JSON.parse(jsonString);
      const themeMode = themeJSON.mode;
      this.clientThemePreference = Theme[themeMode?.toUpperCase()];
    }
    const base64ThemePreferenceFromCookie = this.cookieService.get('theme');
    if (base64ThemePreferenceFromCookie) {
      const themePreferenceFromCookie = JSON.parse(atob(base64ThemePreferenceFromCookie))?.mode;
      this.userThemePreference = Theme[themePreferenceFromCookie?.toUpperCase()];
    }
  }
  initializeTheme() {
    if (this.clientThemePreference) {
      this.setTheme(this.clientThemePreference);
    } else if (this.userThemePreference != null) {
      this.setTheme(this.userThemePreference);
    } else if (this.systemThemePreference != null) {
      this.setTheme(this.systemThemePreference);
    } else {
      this.setTheme(Theme.LIGHT);
    }
  }
  setTheme(theme) {
    this.theme.next(theme);
    this.updateThemingClasses(theme);
    const themeObject = {
      mode: theme,
      seed: '#2962ff'
    };
    const base64Theme = btoa(JSON.stringify(themeObject));
    this.cookieService.setCookie("theme", base64Theme, 1, true, 'Strict');
  }
  updateThemingClasses(theme) {
    if (theme == Theme.SYSTEM) {
      theme = this.getSystemThemePreference();
    }
    switch (theme) {
      case Theme.LIGHT:
        this.renderer.addClass(document.body, 'light-theme');
        this.renderer.removeClass(document.body, 'dark-theme');
        break;
      case Theme.DARK:
        this.renderer.addClass(document.body, 'dark-theme');
        this.renderer.removeClass(document.body, 'light-theme');
        break;
      default:
        this.renderer.addClass(document.body, 'light-theme');
        this.renderer.removeClass(document.body, 'dark-theme');
        break;
    }
  }
  getSystemThemePreference() {
    const isDarkThemeEnabled = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (isDarkThemeEnabled == true) return Theme.DARK;
    return Theme.LIGHT;
  }
  static ɵfac = function ThemeService_Factory(t) {
    return new (t || ThemeService)(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(CookieService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ThemeService,
    factory: ThemeService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.RendererFactory2
  }, {
    type: CookieService
  }], null);
})();
var Theme;
(function (Theme) {
  Theme["LIGHT"] = "light";
  Theme["DARK"] = "dark";
  Theme["SYSTEM"] = "system";
})(Theme || (Theme = {}));

/*
 * Public API Surface of com-quicko-ngx-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthService, CookieService, EntitlementsService, Theme, ThemeService };
